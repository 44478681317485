import React, { useEffect } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './geocoder.css';

function Geocoder() {
    const geocoder = useControl(() => new MapboxGeocoder({
        accessToken: 'pk.eyJ1Ijoid2lsbGlhbWxpbi02ODAzIiwiYSI6ImNtMGZ5dWZodjEwdWYyaW9lbzFrd3YxcnIifQ.kI8dsta2kUTbEPMk9LN1Mg',
        marker: false,
        collapsed: false,
    }));

    useEffect(() => {
        if (geocoder) {
            geocoder.on('result', (e) => {
                const coords = e.result.geometry.coordinates;
                console.log('Selected coordinates:', coords);
            });
        }
    }, [geocoder]);

    return null;
}

export default Geocoder;