import React from 'react';
import { Close, Room } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import './login.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login({ setShowLogin, myStorage, setCurrentUser }) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        try {
            console.log('Attempting login with:', data.username);
            const res = await axios.post('http://localhost:8000/api/users/login', data);
            console.log('Login response:', res.data);
            myStorage.setItem("user", res.data.username);
            setCurrentUser(res.data.username);
            toast.success("Login Successful!");
            setShowLogin(false);
        } catch (err) {
            console.error('Login error:', err);
            if (err.response) {
                console.error('Error response:', err.response.data);
                toast.error(err.response.data.message || 'Invalid username or password!');
            } else if (err.request) {
                console.error('No response received:', err.request);
                toast.error('No response from server. Please try again later.');
            } else {
                console.error('Error setting up request:', err.message);
                toast.error('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className='loginContainer'>
            <div className='loginLogo'>
                <Room />
                Beyond The Page
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    {...register("username", { required: "Username is required" })}
                    placeholder="Enter your username"
                />
                {errors.username && <span className='error'>{errors.username.message}</span>}
                
                <input
                    type="password"
                    {...register("password", { required: "Password is required" })}
                    placeholder="Enter your password"
                />
                {errors.password && <span className='error'>{errors.password.message}</span>}
                
                <button className='loginBtn' type='submit'>Login</button>
                
                <Close
                    className='loginCancel'
                    style={{ fontSize: '1.1rem' }}
                    onClick={() => setShowLogin(false)}
                />
            </form>
        </div>
    );
}