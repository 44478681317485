import React from 'react';
import { Close, Room } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import './register.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Register({ setShowRegister }) {
    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const onSubmit = async (data) => {
        try {
            const response = await axios.post('http://localhost:8000/api/users/register', data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log('Registration response:', response.data);
            toast.success('Registration successful! You can now log in.');
            setShowRegister(false);
        } catch (err) {
            console.error('Registration error:', err.response?.data || err.message);
            toast.error(err.response?.data?.message || 'Registration failed. Please try again.');
        }
    };

    return (
        <div className='registerContainer'>
            <div className='registerLogo'>
                <Room />
                Beyond The Page
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    {...register("username", { required: "Username is required" })}
                    placeholder="Choose a username"
                />
                {errors.username && <span className='error'>{errors.username.message}</span>}

                <input
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    })}
                    placeholder="Enter your email"
                />
                {errors.email && <span className='error'>{errors.email.message}</span>}

                <input
                    type="password"
                    {...register("password", {
                        required: "Password is required",
                        minLength: {
                            value: 5,
                            message: "Password must be at least 5 characters"
                        }
                    })}
                    placeholder="Choose a password"
                />
                {errors.password && <span className='error'>{errors.password.message}</span>}

                <input
                    type="password"
                    {...register("confirmPassword", {
                        required: "Please confirm your password",
                        validate: (val) => {
                            if (watch('password') != val) {
                                return "Your passwords do not match";
                            }
                        },
                    })}
                    placeholder="Confirm your password"
                />
                {errors.confirmPassword && <span className='error'>{errors.confirmPassword.message}</span>}

                <button className='registerBtn' type='submit'>Register</button>

                <Close
                    className='registerCancel'
                    style={{
                        fontSize: '1.1rem',
                    }}
                    onClick={() => setShowRegister(false)}
                />
            </form>
        </div>
    );
}