import React, { useState, useEffect, useRef, useCallback } from 'react';
import Map, { Marker, Popup, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Room, Star, AccountCircle, RateReview, MyLocation, Help, Menu, Bookmark, History, Restaurant, Hotel, Attractions, Logout } from '@mui/icons-material';
import './app.css';
import axios from 'axios';
import { format } from 'timeago.js';
import Register from './components/Register';
import Login from './components/Login';
import Geocoder from './components/Geocoder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import { debounce } from 'lodash';

function App() {
    const myStorage = window.localStorage;
    const [currentUser, setCurrentUser] = useState(myStorage.getItem("user"));
    const [pins, setPins] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(null);
    const [newPlace, setNewPlace] = useState(null);
    const [title, setTitle] = useState(null);
    const [desc, setDesc] = useState(null);
    const [rating, setRating] = useState(0);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [viewport, setViewport] = useState({
        latitude: 39.8283,
        longitude: -98.5795,
        zoom: 4,
    });

    useEffect(() => {
        const getPins = async () => {
            try {
                const res = await axios.get('http://localhost:8000/api/pins');
                setPins(res.data);
            } catch (err) {
                console.error('Error fetching pins:', err.response ? err.response.data : err.message);
                toast.error('Failed to fetch pins. Please try again later.');
            }
        }
        getPins();
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            const mapElement = mapRef.current.getMap().getCanvas();
            mapElement.addEventListener('touchstart', handleTouchStart, { passive: false });
            return () => {
                mapElement.removeEventListener('touchstart', handleTouchStart);
            };
        }
    }, [mapRef]);

    const handleTouchStart = (e) => {
        if (e.touches.length > 1) {
            e.preventDefault();
        }
    };

    const onMapLoad = useCallback((event) => {
        console.log("Map Loaded Successfully");
        setMapLoaded(true);
    }, []);

    const handleViewportChange = useCallback((nextViewport) => {
        setViewport(nextViewport);
    }, []);

    const handleMarkerClick = useCallback((id, lat, long) => {
        setCurrentPlaceId(id);
        setViewport(prev => ({ ...prev, latitude: lat, longitude: long }));
    }, []);

    const handleAddClick = useCallback((e) => {
        if (currentUser && mapLoaded) {
            const { lng, lat } = e.lngLat;
            setNewPlace({
                long: lng,
                lat: lat,
            });
        }
    }, [currentUser, mapLoaded]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPin = {
            username: currentUser,
            title,
            desc,
            rating,
            lat: newPlace.lat,
            long: newPlace.long,
        }
        try {
            const res = await axios.post("http://localhost:8000/api/pins", newPin);
            setPins([...pins, res.data]);
            setNewPlace(null);
            toast.success('Pin added successfully!');
        } catch (err) {
            console.error('Error adding pin:', err);
            toast.error('Failed to add pin. Please try again.');
        }
    }

    const handleLocationClick = useCallback((event) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        if (navigator.geolocation) {
            toast.info("Fetching your location!");
            navigator.geolocation.getCurrentPosition((position) => {
                toast.success("Location fetched successfully!");
                let lat = position.coords.latitude;
                let lon = position.coords.longitude;
                setNewPlace({
                    long: lon,
                    lat: lat,
                });
                setViewport(prev => ({ ...prev, latitude: lat, longitude: lon, zoom: 14 }));
            }, (err) => {
                console.error('Error getting location:', err);
                toast.error('Failed to fetch location. Please try again.');
            });
        } else {
            toast.error('Geolocation is not supported by your browser.');
        }
    }, []);

    const handleLogout = useCallback(() => {
        const answer = window.confirm("Are you sure you want to log out?");
        if (answer) {
            myStorage.removeItem("user");
            setCurrentUser(null);
            toast.info('Logged out successfully!');
        }
    }, [myStorage]);

    const handleClosePopup = useCallback(() => {
        setNewPlace(null);
        setCurrentPlaceId(null);
    }, []);

    const handleMapError = useCallback((error) => {
        console.error('Mapbox GL Error:', error);
        toast.error('An error occurred with the map. Please try refreshing the page.');
    }, []);

    const debouncedHandleMouseEvent = useCallback(
        debounce(() => {
            // This empty function will be called on mouseover, mousemove, and mouseout
            // The debounce reduces the frequency of these calls
        }, 100),
        []
    );

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="App">
            <Map
                ref={mapRef}
                initialViewState={viewport}
                onMove={handleViewportChange}
                mapboxAccessToken="pk.eyJ1Ijoid2lsbGlhbWxpbi02ODAzIiwiYSI6ImNtMGZ5dWZodjEwdWYyaW9lbzFrd3YxcnIifQ.kI8dsta2kUTbEPMk9LN1Mg"
                style={{ width: '100vw', height: '100vh' }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onLoad={onMapLoad}
                onError={handleMapError}
                onDblClick={handleAddClick}
                onMouseOver={debouncedHandleMouseEvent}
                onMouseMove={debouncedHandleMouseEvent}
                onMouseOut={debouncedHandleMouseEvent}
            >
                {pins.map((p) => (
                    <React.Fragment key={p._id}>
                        <Marker
                            longitude={p.long}
                            latitude={p.lat}
                            anchor="bottom"
                        >
                            <Room
                                style={{
                                    fontSize: (viewport.zoom ?? 4) * 7,
                                    color: p.username === currentUser ? '#af0101' : "#0039a6",
                                    cursor: "pointer"
                                }}
                                onClick={() => handleMarkerClick(p._id, p.lat, p.long)}
                            />
                        </Marker>
                        {p._id === currentPlaceId && (
                            <Popup
                                longitude={p.long}
                                latitude={p.lat}
                                anchor="left"
                                closeButton={true}
                                closeOnClick={false}
                                onClose={handleClosePopup}
                            >
                                <div className='card'>
                                    <label>Place</label>
                                    <h2 className='place'>{p.title}</h2>
                                    <label>Review</label>
                                    <p className='desc'>{p.desc}</p>
                                    <label>Rating</label>
                                    <div className="stars">
                                        {Array(p.rating).fill(<Star className='star' />)}
                                    </div>
                                    <label>Information</label>
                                    <span className='username'>Created by <b>{p.username}</b></span>
                                    <span className='date'>{format(p.createdAt)}</span>
                                </div>
                            </Popup>
                        )}
                    </React.Fragment>
                ))}
                {newPlace && (
                    <Popup
                        longitude={newPlace.long}
                        latitude={newPlace.lat}
                        anchor="left"
                        closeButton={true}
                        closeOnClick={true}
                        onClose={handleClosePopup}
                    >
                        <div className='card'>
                            <form className='pinForm' onSubmit={handleSubmit}>
                                <label>Title</label>
                                <input
                                    placeholder="Enter a title"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <label>Review</label>
                                <textarea
                                    placeholder="Tell us about this place"
                                    onChange={(e) => setDesc(e.target.value)}
                                />
                                <label>Rating</label>
                                <select onChange={(e) => setRating(parseInt(e.target.value))}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <button className='submitButton' type='submit'>Add Pin</button>
                            </form>
                        </div>
                    </Popup>
                )}
                {mapLoaded && <Geocoder />}
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        bottom: 100,
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        gap: '10px'
                    }}>
                        <NavigationControl showCompass={false} position='bottom-right' />
                        <Tooltip title="Pin my location" placement="left" arrow>
                            <button
                                className='button location'
                                onClick={handleLocationClick}
                                onTouchStart={(e) => {
                                    e.preventDefault();
                                    handleLocationClick(e);
                                }}
                            >
                                <MyLocation style={{ fontSize: '1.3rem' }} />
                            </button>
                        </Tooltip>
                    </div>
            </Map>

            <button
                className={`button sidebar-toggle ${sidebarOpen ? 'open' : ''}`}
                onClick={toggleSidebar}
            >
                <Menu style={{ fontSize: "2rem" }} />
            </button>

            {currentUser ? (
                <div className="userInfo">
                    <div className="welcome-text">
                        <AccountCircle style={{ marginRight: '5px' }} />
                        <p><b>{currentUser}</b></p>
                    </div>
                    <div className="icons">
                        <Tooltip title="Help" arrow>
                            <Help className="icon" onClick={(e) => {
                                e.stopPropagation();
                                toast.info("Help functionality to be implemented.");
                            }} />
                        </Tooltip>
                        <Tooltip title="Logout" arrow>
                            <Logout className="icon" onClick={(e) => {
                                e.stopPropagation();
                                handleLogout();
                            }} />
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <div className="buttons">
                    <button className="button login" onClick={() => {
                        setShowRegister(false);
                        setShowLogin(true);
                    }}>Login</button>
                    <button className="button register" onClick={() => {
                        setShowLogin(false);
                        setShowRegister(true);
                    }}>Register</button>
                </div>
            )}
            {showRegister && <Register setShowRegister={setShowRegister} />}
            {showLogin && (
                <Login
                    setShowLogin={setShowLogin}
                    myStorage={myStorage}
                    setCurrentUser={setCurrentUser}
                />
            )}

            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-items">
                    <div className="sidebar-item"><Bookmark /></div>
                    <div className="sidebar-item"><History /></div>
                    <div className="sidebar-item"><Restaurant /></div>
                    <div className="sidebar-item"><Hotel /></div>
                    <div className="sidebar-item"><Attractions /></div>
                </div>
            </div>

            <ToastContainer
                autoClose={3000}
                theme="dark"
            />
        </div>
    );
}

export default App;